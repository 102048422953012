import { render, staticRenderFns } from "./TextInput.vue?vue&type=template&id=a4c889d2&scoped=true"
import script from "./TextInput.vue?vue&type=script&lang=js"
export * from "./TextInput.vue?vue&type=script&lang=js"
import style0 from "./TextInput.vue?vue&type=style&index=0&id=a4c889d2&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a4c889d2",
  null
  
)

export default component.exports